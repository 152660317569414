
export default {
    layout: "blank",
    async asyncData({ req, params, $content }) {
        const page_data = await $content('getsalvor.com/pages/index').fetch();
        return {
            page_data
        }
    },
    data() {
        return {
            host: null
        }
    }
}
